import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/invoice'

// QUOTE

export const fetchInvoicesRequest = params => globalApi.get(END_POINT, { params })

export const fetchInvoiceRequest = invoiceId => globalApi.get(`${END_POINT}/${invoiceId}`)

export const patchInvoiceRequest = (invoice, message) => globalApi.patch(`${END_POINT}/${invoice.id}`, invoice, { message })
// remove ??
export const deleteInvoiceRemoveRequest = (invoiceId, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed
      && globalApi.delete(`${END_POINT}/${invoiceId}/remove`, { message }),
)

// FILTERS

export const fetchInvoiceFiltersRequest = () => globalApi.get('/api/invoice-filters')
