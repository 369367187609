<template>
  <div id="invoice-view">
    <section>
      <b-row class="invoice-preview">
        <b-col></b-col>
        <b-col cols="12" xl="9">
          <supply-document
            id="invoice-document"
            :invoice-configuration="invoiceConfiguration"
            :loading="invoiceLoading"
            :marketplace-invoice="marketplaceInvoice"
            :supply="invoice"
            :title="$t('common.invoice')"
          />
        </b-col>
        <b-col></b-col>
      </b-row>
    </section>
    <SupplyDocumentPdf :invoice-configuration="invoiceConfiguration" :supply="invoice" :title="$t('common.invoice')" show-print />
  </div>
</template>
<script>
import { fetchInvoiceRequest } from '@/request/globalApi/requests/invoiceRequests'

import SupplyDocument from '@/components/SupplyDocument.vue'
import SupplyDocumentPdf from '@/components/SupplyDocumentPdf.vue'

export default {
  name: 'InvoiceView',

  components: {
    SupplyDocument,
    SupplyDocumentPdf,
  },

  data() {
    return {
      invoiceLoading: false,
      marketplaceInvoice: false,
      invoice: {},
      invoiceConfiguration: {},
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.invoiceLoading = true
      fetchInvoiceRequest(this.$route.params.id)
        .then(response => {
          this.invoice = response.data.invoice
          this.invoiceConfiguration = response.data.invoiceConfiguration
          this.marketplaceInvoice = response.data.marketplaceInvoice
        })
        .finally(() => {
          this.invoiceLoading = false
        })
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
</style>
